<template>
  <div class="exam-result">
    <Breadcrumb class="custom-breadcrumb">
      <BreadcrumbItem>考试</BreadcrumbItem>
      <BreadcrumbItem>考试详情</BreadcrumbItem>
      <BreadcrumbItem>{{ exam.name }}</BreadcrumbItem>
    </Breadcrumb>

    <div class="header">
      <div class="user-name">{{ username }}</div>
      <div class="item-container">
        <div class="item">
          <div>{{ detail.score }} 分</div>
          <div>得分（满分 {{ exam.total }}）</div>
        </div>
        <div class="item">
          <div>{{ detail.duration }} 分钟</div>
          <div>用时（限时 {{ exam.limitTime }} 分）</div>
        </div>
        <div class="item">
          <div>{{ correctCount }}</div>
          <div>正确题数（共 {{ questionCount }} 题）</div>
        </div>
        <div class="item">
          <div>通过</div>
          <div>是否通过</div>
        </div>
      </div>
    </div>

    <div class="content">
      <div class="answer-sheet">
        <div class="title">答卷信息</div>
        <div class="answer-content">
          <div class="answer-item" v-for="section in detail.sections" :key="section.answerSectionId">
            <div class="section-name">{{ section.name }}</div>
            <div class="question-container">
              <div
                class="question-index"
                v-for="question in section.questions"
                :key="question.id"
                @click="handleScrollTo(question.id)"
                :class="{
                  error: question.dtoStatus === QUESTION_ANSWER_STATUS.WRONG.value,
                  correct: question.dtoStatus === QUESTION_ANSWER_STATUS.RIGHT.value
                }"
              >
                {{ question.question.order }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="paper question-image-size-restrict">
        <div class="question-container" v-for="section in detail.sections" :key="section.answerSectionId">
          <div class="section-name">
            {{ section.name }} (共 {{ section.questions.length }} 题，共 {{ section.score }} 分，得分 {{ section.gainedScore }} 分)
          </div>
          <div v-for="(question, index) in section.questions" :id="`q${question.id}`" :key="question.id" class="question-item">
            <div>{{ index + 1 }}.</div>
            <BaseQuestion :question="question.question" :show-answer="true" :show-score="true"> </BaseQuestion>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import examApi from '@api/exam'
import nzhcn from 'nzh/cn'
import { QUESTION_ANSWER_STATUS, getStatusByValue } from '@/util/questionAnswerStatus'
import BaseQuestion from '@/components/common/questions/BaseQuestion'

export default {
  name: 'UserExamResult',
  components: { BaseQuestion },
  data: function() {
    return {
      detail: {},
      exam: {}
    }
  },
  created() {
    examApi.getExamDetail(this.examId).then(res => {
      this.exam = res.res
    })

    //先获取 answerPaperId 再获取详情
    examApi
      .getUserExamAnswerPaper(this.examId, this.userId)
      .then(res => {
        //考生作答结果，可能多次作答，为数组
        const data = res.res
        if (!data.length) {
          return
        }

        this.detail = res.res[0]
        return res.res[0].answerPaperId
      })
      .then(answerPaperId => {
        if (!answerPaperId) {
          return
        }
        examApi.getUserExamDetail(answerPaperId).then(res => {
          let data = res.res
          // 排序
          data.sections.sort((a, b) => a.order - b.order)
          data.sections.forEach((section, sectionIndex) => {
            section.name = nzhcn.encodeS(sectionIndex + 1) + '、' + section.name
            section.questions.sort((a, b) => a.question.order - b.question.order)
            let sumScore = 0
            let gainedScore = 0
            section.questions.forEach((question, questionIndex) => {
              question.sectionIndex = sectionIndex
              question.questionIndex = questionIndex
              question.question.userAnswer = question.answer
              question.question.userScore = question.userScore
              //若得分与题目分数相同则认为正确
              question.question.correct = question.userScore === question.score
              sumScore += question.score
              gainedScore += question.userScore || 0
            })
            section.score = sumScore
            section.gainedScore = gainedScore
          })

          this.detail = data
        })
      })
  },
  methods: {
    handleScrollTo(questionId) {
      document.querySelector(`#q${questionId}`).scrollIntoView({
        behavior: 'smooth'
      })
    }
  },
  computed: {
    userId() {
      return Number(this.$route.params.userId)
    },
    username() {
      return this.$route.query.username
    },
    examId() {
      return Number(this.$route.params.examId)
    },
    questionCount() {
      if (!this.detail.sections) {
        return 0
      }

      return this.detail.sections.reduce((pre, cur) => {
        pre += cur.questions.length
        return pre
      }, 0)
    },
    correctCount() {
      if (!this.detail.sections) {
        return 0
      }

      return this.detail.sections.reduce((pre, cur) => {
        const correctCount = cur.questions.reduce((preCorrectCount, curQuestion) => {
          if (curQuestion.dtoStatus === QUESTION_ANSWER_STATUS.RIGHT.value) {
            return preCorrectCount + 1
          } else {
            return preCorrectCount
          }
        }, 0)

        return pre + correctCount
      }, 0)
    },
    QUESTION_ANSWER_STATUS() {
      return QUESTION_ANSWER_STATUS
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/theme/variables.less';

.exam-result {
  padding: 10px 20px;
  background-color: @layout-body-background;
  scroll-behavior: smooth;

  .header {
    color: white;
    padding: 20px 12px;
    text-align: left;
    margin-top: 10px;
    background: url('../../../../../assets/exam_result_user_bg.png');
    background-size: 100% 100%;

    .user-name {
      font-size: 16px;
      font-weight: bold;
    }

    .item-container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .item > div:first-child {
        font-size: 16px;
        font-weight: bold;
      }
    }
  }

  .content {
    display: flex;
    margin-top: 10px;
    align-items: flex-start;
    flex: 1 1 auto;

    .answer-sheet {
      background-color: white;
      box-shadow: 0px 2px 3px 0px rgba(175, 179, 181, 0.5);
      border-radius: 3px;
      width: 20%;
      min-width: 200px;
      max-width: 250px;
      text-align: left;
      flex: 0 0 auto;
      position: sticky;
      top: 0;

      .title {
        background-color: #3c88d3;
        padding: 4px 10px;
        border-radius: 3px 3px 0 0;
        color: white;
        font-size: 14px;
        text-align: center;
      }

      .answer-content {
        padding: 26px 10px;

        .answer-item {
          border-bottom: 1px solid #a6acb9;
          padding: 10px 0;

          &:last-child {
            border-bottom: none;
          }

          .section-name {
            color: @font-color-base;
            font-weight: bold;
            font-size: 14px;
            margin-left: 4px;
          }

          .question-container {
            color: @font-color-content;
            display: flex;

            .question-index {
              display: inline-block;
              width: 18px;
              height: 18px;
              text-align: center;
              border-radius: 3px;
              background-color: #f7f9f9;
              margin: 4px;
              cursor: pointer;

              &.error {
                background-color: #ed4014;
                color: white;
              }

              &.correct {
                background-color: #19be6b;
                color: white;
              }
            }
          }
        }
      }
    }

    .paper {
      text-align: left;
      background-color: white;
      margin-left: 10px;
      flex: 1 1 auto;
      padding: 18px 30px;
      box-shadow: 0px 2px 3px 0px rgba(175, 179, 181, 0.5);
      border-radius: 3px;

      .section-name {
        font-size: 14px;
        font-weight: bold;
        padding: 10px 0;
      }

      .question-item {
        display: flex;
        padding: 20px 0;
        border-top: 1px solid rgba(175, 175, 175, 0.2);
      }
    }
  }
}
</style>
